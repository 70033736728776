<template>
    <div class="welcome">
        <img
            class="welcome__image"
            src="../../assets/images/super_hero.png"
            alt="Member"
        />
        <div class="welcome__container">
            <img src="../../assets/images/tenant-logo.svg" alt="" class="tenant-logo" />
            <h1 class="welcome__title">
                Congratulations
                <br>Your organization is a <i>Gold Member</i>
            </h1>
            <p>As part of the First Service Residential organization, your property could enjoy
                of the Gold Member prices and dedicated support.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Welcome',
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.welcome {
    height: 100%;
    position: relative;
    padding-top: 210px;

    &__image {
        left: 210px;
        position: absolute;
        z-index: 0;
        top: -40px;
    }

    &__container {
        position: relative;

        .tenant-logo {
            margin-bottom: 40px;
        }

        p {
            line-height: 23px;
            font-weight: 300;
            margin-top: 20px;
            max-width: 413px;
        }
    }

    &__title {
        font-size: 35px;
        line-height: 45px;
        font-weight: 400;
        font-family: $alternate-font;
        color: $primary-color;

        i {
            font-size: 40px;
            font-weight: 700;
        }
    }
}
</style>